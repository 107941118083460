import domReady from '@roots/sage/client/dom-ready';
import {Splide} from '@splidejs/splide';
import baguetteBox from 'baguettebox.js';

/**
 * Application entrypoint
 */
domReady(async() => {

    document.body.classList.add("loaded");

  // ...
    const navToggle = Array.from(document.querySelectorAll('[data-nav-toggle]'));
    const nav = document.querySelector('.nav-primary');

    navToggle.forEach((el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();
            const target = nav;
            target.classList.toggle('is-active');
        });
    })


    // Newsletter button
    const popupNewsletter = document.getElementById('popupNewsletter');
    const newsletterButton = Array.from(document.querySelectorAll('[data-popup=popupNewsletter]'));

    newsletterButton.forEach((el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();
            const target = popupNewsletter;
            target.classList.toggle('hidden');
        });
    });



    // Slider
    if (document.querySelector('.splide')) {
        var splide = new Splide('.splide', {
            type: 'loop',
            height: '340px',
            focus: 'center',
            gap: '45px',
            autoWidth: true,
            breakpoints: {
                991: {
                    height: '260px',
                },
            }
        });
        splide.mount();
    }

    // throttle
    const throttle = (cb, delay = 1000) => {
        let shouldWait = false;
        let waitingArgs;
        const timeoutFunc = () => {
            if (waitingArgs == null) {
                shouldWait = false;
            } else {
                cb(...waitingArgs);
                waitingArgs = null;
                setTimeout(timeoutFunc, delay);
            }
        }
        return (...args) => {
            if (shouldWait) {
                waitingArgs = args;
                return;
            }
            cb(...args);
            shouldWait = true;
            setTimeout(timeoutFunc, delay);
        }
    }

    // watch for scroll event
    const dynamicHeader = document.querySelector('header + header');
    if (dynamicHeader) {
        window.addEventListener('scroll', throttle(function () {
            if (window.scrollY > 64) {
                dynamicHeader.classList.add('translate-y-0');
                dynamicHeader.classList.remove('-translate-y-full');
            } else {
                dynamicHeader.classList.remove('translate-y-0');
                dynamicHeader.classList.add('-translate-y-full');
            }
        }), 500);
    }


    // open links in a new tab
    const targetBlank = Array.from(document.querySelectorAll(".target-blank a"));
    targetBlank.forEach((el) => {
        el.setAttribute("target", "_blank");
    })

    // Cookie functions
    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }


    // Acknowledgement of country
    const acknowledgement = document.getElementById('acknowledgement');
    const dismissAcknowledgement = function () {
        setCookie('acknowledgement', 'true', 365);
        acknowledgement.classList.add('hidden');
    }
    if (acknowledgement && getCookie('acknowledgement') !== 'true') {
        acknowledgement.classList.remove('hidden');
        acknowledgement.addEventListener('click', dismissAcknowledgement);
        window.setTimeout(dismissAcknowledgement, 12000);
    }




    // Lightbox
    baguetteBox.run('.gallery');



    //  Filters
    const categoryFilter = document.getElementById('categoryFilter');
    const venueFilter = document.getElementById('venueFilter');
    const dateFilter = document.getElementById('dateFilter');
    const events = Array.from(document.querySelectorAll('article'));

    if (typeof events !== 'undefined' && events.length > 0) {
        const noResultsBlock = document.getElementById('noResults');
        const filters = {
            category: categoryFilter,
            venue: venueFilter,
            date: dateFilter
        };

        for (const [filterName, filterElement] of Object.entries(filters)) {
            if (filterElement) {
                filterElement.addEventListener('change', (e) => {
                    let empty = true;
                    const activeValue = e.target.value;
                    const activeValues = {
                        category: categoryFilter.value,
                        venue: venueFilter.value,
                        date: dateFilter.value
                    };

                    events.forEach((event) => {
                        let isMatch = true;
                        for (const [key, value] of Object.entries(activeValues)) {
                            if (value !== 'all' && !event.dataset[key].includes(value)) {
                                isMatch = false;
                            }
                        }
                        event.style.display = isMatch ? 'grid' : 'none';
                        empty = empty && !isMatch;
                    });
                    if (noResultsBlock) {
                        noResultsBlock.classList[empty ? 'remove' : 'add']('hidden');
                    }
                });
            }
        }
    }

    // Unload
    // Get all internal links on the page
    const internalLinks = Array.from(document.querySelectorAll('a:not([download])[href^="/"]:not([href^="//"]'));
    // const internalLinks = Array.from(document.querySelectorAll('a:not([download])[href^="/"]:not([href^="//"]')).concat(Array.from(document.querySelectorAll('a:not([download])[href*="'+window.location.host+"]')));

    // Loop through each internal link
    internalLinks.forEach(link => {
        // Add an event listener for the click event
        link.addEventListener('click', () => {
            // Add the "unloaded" class to the body tag
            document.body.classList.add('unloading');
        });
    });

});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
